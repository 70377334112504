import './RecentCard.css';

const RecentCard = ({ search }) => {
    const handleClick = (text) => {
        search(text);
    };

    return (
        <div className='RecentCard'>
            <div className='Header'>Recent Searched Topics</div>
            <div className='Content'>
                <div className='Pil' onClick={() => handleClick('Does recent gene therapy research show significant advancements?')}>Does recent gene therapy research show significant advancements?</div>
                <div className='Pil' onClick={() => handleClick('Are RNA vaccines the most effective according to global research?')}>Are RNA vaccines the most effective according to global research?</div>
                <div className='Pil' onClick={() => handleClick('What is the impact of personalized medicine approaches on treatment outcomes?')}>What is the impact of personalized medicine approaches on treatment outcomes?</div>
                <div className='Pil' onClick={() => handleClick('Do the latest clinical trials on COVID-19 show promising results?')}>Do the latest clinical trials on COVID-19 show promising results?</div>
                <div className='Pil' onClick={() => handleClick('Does new research on creatine enhance muscle performance in weight training?')}>Does new research on creatine enhance muscle performance in weight training?</div>
            </div>
        </div>
    );
};

export default RecentCard;