import React from 'react';
import './TrustCard.css';
import RangeIndicator from './RangeIndicator';
import ReviewIndicator from './ReviewIndicator';

const TrustCard = ({trustScore, trustText}) => {
  const rangeData = [
    { label: 'Fair', range: '0% - 35%', color: '#ff5101' },
    { label: 'Moderate', range: '36% - 70%', color: '#e29b10' },
    { label: 'High', range: '71% - 100%', color: '#399d39' },
  ];

  const reviewData = [
    { label: 'Peer reviewed', color: '#0480c6' },
    { label: 'Not reviewed', color: '#acaaa3' },
  ];


  const getGradientColor = (score) => {
    if (score <= 35) return '#ff5101';
    if (score <= 70) return '#e29b10';
    return '#399d39';
  };

  const calculateRotation = (percentage) => {
    return ((percentage / 100) * 360);
  };

  const circleStyle = {
    background: `conic-gradient(
      ${getGradientColor(trustScore)} ${calculateRotation(trustScore)}deg,
      #E8E8E8 ${calculateRotation(trustScore)}deg 360deg
    )`
  };

  return (
    <section className="container">
      <div className="orderCard">
        <div className="innerCard">
          <div className="contentWrapper">
            <div className="mainColumn">
              <div className="trustScoreContent">
                <h2 className="trustScoreTitle">MedRPT Findings</h2>
                <p className="trustScoreDescription">
                  {trustText}
                </p>
                <img 
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/45d05b6d84ebdd0c604605c9e321d10241a9c5bacd925481bbd448b5c3143648?placeholderIfAbsent=true&apiKey=fea631323e6d4a3aa3a8e027978d96a2" 
                  alt="Trust Score Graph" 
                  className="trustScoreImage" 
                />
                <h3 className="keyRangeTitle">Trust Score Scale</h3>
                <div className="keyRangeWrapper">
                  <RangeIndicator data={rangeData} />
                </div>
              </div>
            </div>
            <div className="scoreColumn">
              <div className="scoreWrapper">
                <h2 className="trustScoreTitle">Trust Score</h2>
                <div className="scoreCircle" style={circleStyle}>
                  <div className="scoreInner">
                    <span className="scoreValue">{trustScore}</span>
                    <span className="scorePercentage">%</span>
                  </div>
                </div>
                <p className="scoreDescription">
                  {trustScore >= 70 ? "High" : trustScore >= 35 ? "Moderate" : "Fair"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustCard;