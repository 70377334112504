import React from 'react';
import { DisclaimerCard } from '../../Components/DisclaimerCard/DisclaimerCard';

function Disclaimer() {
  return (
    <DisclaimerCard />
  );
}

export default Disclaimer;

