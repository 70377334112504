import './TopBar.css';
import { useNavigate } from 'react-router-dom';

export const TopBar = () => {
    const navigate = useNavigate();

    return (
        <div className='TopBar'>
            <div className='MedRPT' onClick={() => navigate('/')}>
                <svg className="responsive-svg" viewBox="0 0 184 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M89.6724 10.9168H97.74C99.3525 10.9168 100.835 11.1788 102.187 11.7027C103.534 12.2266 104.696 12.9571 105.672 13.8891C106.649 14.821 107.4 15.9142 107.931 17.1736C108.462 18.4331 108.727 19.7932 108.727 21.2592V21.3196C108.727 22.7856 108.462 24.1559 107.931 25.4203C107.4 26.6898 106.644 27.788 105.672 28.72C104.696 29.652 103.534 30.3824 102.187 30.9215C100.84 31.4555 99.3576 31.7225 97.74 31.7225H89.6724V15.1737V10.9168ZM97.74 27.5916C98.6665 27.5916 99.5128 27.4455 100.279 27.1482C101.045 26.851 101.701 26.4228 102.242 25.8687C102.783 25.3145 103.209 24.6596 103.514 23.909C103.82 23.1534 103.97 22.3121 103.97 21.3801V21.3196C103.97 20.4078 103.82 19.5665 103.514 18.7958C103.209 18.025 102.783 17.36 102.242 16.8059C101.701 16.2517 101.045 15.8185 100.279 15.5112C99.5128 15.2039 98.6615 15.0528 97.74 15.0528H89.7124L89.6774 27.3447V27.5966H97.74V27.5916Z" fill="#01344A"/>
                <path d="M44.2314 10.9168H49.1341L54.5727 19.7126L60.0112 10.9168H64.9139V31.7225H60.4218V18.1409L54.6027 27.0273H54.4825L48.7235 18.2316V31.7275H44.2314V10.9168Z" fill="#01344A"/>
                <path d="M112.779 10.9168H122.234C124.853 10.9168 126.861 11.6221 128.264 13.0276C129.445 14.2165 130.036 15.8034 130.036 17.7832V17.8436C130.036 19.5262 129.626 20.9015 128.809 21.9594C127.993 23.0173 126.921 23.7982 125.604 24.2919L130.657 31.7225H125.339L120.907 25.0626H120.847H117.331V31.7225H112.779V10.9168ZM121.939 21.0224C123.06 21.0224 123.922 20.7554 124.523 20.2214C125.124 19.6874 125.424 18.9721 125.424 18.0804V18.02C125.424 17.0275 125.109 16.287 124.478 15.7933C123.847 15.2996 122.97 15.0528 121.849 15.0528H117.326V21.0224H121.939Z" fill="#49994C"/>
                <path d="M134.142 10.9168H142.59C143.832 10.9168 144.939 11.0881 145.916 11.4357C146.892 11.7833 147.718 12.272 148.399 12.9067C149.081 13.5415 149.601 14.2971 149.967 15.1787C150.332 16.0603 150.513 17.0376 150.513 18.1056V18.1661C150.513 19.3751 150.297 20.433 149.862 21.3448C149.426 22.2567 148.83 23.0173 148.074 23.6319C147.318 24.2465 146.431 24.705 145.415 25.0123C144.398 25.3196 143.311 25.4757 142.15 25.4757H138.694V31.7174H134.142V10.9168ZM142.295 21.4103C143.437 21.4103 144.323 21.1081 144.954 20.5035C145.585 19.899 145.901 19.1534 145.901 18.2618V18.2013C145.901 17.1736 145.57 16.3878 144.909 15.8538C144.248 15.3198 143.347 15.0528 142.205 15.0528H138.689V21.4154H142.295V21.4103Z" fill="#49994C"/>
                <path d="M158.696 15.1384H152.401V10.9168H169.538V15.1384H163.243V31.7225H158.691V15.1384H158.696Z" fill="#49994C"/>
                <path d="M69.8818 10.9168H85.4512V14.9873H74.3739V19.2089H98.6469V23.2793H74.3739V27.647H85.6015V31.7174H69.8818V10.9168Z" fill="#01344A"/>
                <path d="M25.2659 10.3868L28.6042 12.3013L19.5461 17.8172V22.8518L35.5174 13.7398V10.8629L17.6378 0.312347L0 11.106V16.3787L17.7561 5.82821L21.2127 7.74789L0 19.9749V25.2527L25.2659 10.3868Z" fill="#01344A"/>
                <path d="M31.6733 19.3813L31.7093 28.3536L17.8778 36.5239L6.49477 30.0404L16.8594 24.0537V19.0191L0.234863 28.9383V31.6755L17.8778 42.1898L35.7522 31.541V16.97L31.6733 19.3813Z" fill="#50A854"/>
                </svg>
                <div className='BetaText'>BETA</div>
            </div>
            <div>&nbsp;</div>
            <div className='TopBarContainer'>
                <div className='TopBarText' onClick={() => navigate('/')}>Home</div>
                <div className='TopBarText' onClick={() => navigate('/disclaimer')}>Disclaimer</div>
            </div>
        </div>
    );
}
